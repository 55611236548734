.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: transparent;
  }
  
  .logo-name {
    width: 25%;
    height: 25%;
    display: flex;
    align-items: center;
  }
  
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    border-radius: 50%;
  }

  .logo img {
    width: 90%;
    height: 90%;
    border-radius: 50%;
  }
  
  .nav-btns {
    display: flex;
    gap: 20px;
  }
  
  .log-out {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    /* background-color: rgba(48, 33, 61, 0.2); */
    background: linear-gradient(45deg, #8A2BE2, #9B30FF);
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .log-out img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  .log-out:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  }
  
  .log-out:active {
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
  }
  
  .log-out img {
    width: 24px;
    height: 24px;
  }
  

  /* Responsive adjustments for tablets and smaller devices */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 15px;
  }

  .logo-name {
    width: 35%;
    justify-content: center;
    display: none;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .logo img {
    width: 100%;
    height: 100%;
  }

  .nav-btns {
    gap: 15px;
  }

  .log-out {
    width: 40px;
    height: 40px;
  }

  .log-out img {
    width: 20px;
    height: 20px;
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 480px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .logo-name {
    display: none;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .nav-btns {
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }

  .log-out {
    width: 35px;
    height: 35px;
  }

  .log-out img {
    width: 18px;
    height: 18px;
  }
}