/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #1B1D1E;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 30%;
    height: 80%;
    overflow-y: hidden;
  }
  
  .modal-close {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }


  /* Responsive adjustments for tablets and smaller devices */
@media (max-width: 768px) {
  .modal-content {
    width: 50%;
    height: 70%;
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 480px) {
  .modal-content {
    width: 80%;
    height: auto;
    max-height: 90%;
    padding: 15px;
    overflow-y: auto;
  }

  .modal-close {
    font-size: 20px;
    top: 5px;
    right: 5px;
  }
}
  