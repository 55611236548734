.all-album-view {
  color: #FFFFFF;
  /* background: linear-gradient(135deg, #181A1B 0%, #1D1F21 40%, #30213D 100%); */
  background: url('../Assets/back.jpg') no-repeat center center/cover;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.allalbum-image-container {
  /* position: relative; */
  width: 100%;
  height: 60vh; /* Make it cover full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.allalbum-content {
  text-align: center;
  font-size: 48px;
  margin-bottom: 20px;
  /* font-family: "Italianno", cursive;
  font-style: italic; */
}

.button-container {
  display: flex;
  gap: 20px;
  bottom: 10%; /* Adjusted position for better visibility */
  justify-content: space-evenly;
  width: 30%;
}

.popup-button {
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  background: linear-gradient(45deg, #8A2BE2, #9B30FF);
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.popup-button:hover {
  background: linear-gradient(45deg, #9B30FF, #8A2BE2);
  transform: scale(1.05);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #232528;
  padding: 20px;
  width: 40vw;
  height: 40vh;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.popup .input {
  width: 90%;
  padding: 12px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  background-color: #1B1D1E;
  color: white;
}

.popup .input:focus {
  outline: none;
  border: 1px solid #8A2BE2;
}

.popup .input::placeholder {
  color: grey; /* Match the input text color */
  font-size: 18px; /* Match the input text size */
  opacity: 1; /* Ensure the placeholder is fully opaque */
}

.submit-btn {
  display: flex;
  justify-content: center;
}

.submit-button {
  width: 50%;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  color: #bbb;
}

.albums-container {
  padding: 20px 20px;
  background: linear-gradient(135deg, #181A1B 0%, #1D1F21 40%, #30213D 100%);
  border-radius: 5% 5% 0 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.album-section-title {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 36px;
  color: #FFFFFF;
  margin-bottom: 2%;
}

.album-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.album-card {
  padding: 20px;
  font-size: 20px;
  background-color: #232528;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  max-width: 250px;
  overflow: hidden;
}

.album-card:hover {
  border: 1px solid #8A2BE2;
  background-color: #1B1D1E;
  transform: scale(1.05);
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #1B1D1E;
  font-size: 14px;
  color: #B3B3B3;
}


/* Responsive adjustments for tablets and smaller devices */
@media (max-width: 768px) {
  .allalbum-content {
    font-size: 36px;
  }

  .button-container {
    width: 50%;
    gap: 10px;
  }

  .popup {
    width: 60vw;
    height: auto;
    padding: 15px;
  }

  .popup .input {
    font-size: 16px;
  }

  .submit-button {
    width: 70%;
    font-size: 16px;
  }

  .album-section-title {
    font-size: 28px;
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 480px) {
  .allalbum-image-container {
    height: 40vh;
  }

  .allalbum-content {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .button-container {
    width: 80%;
    flex-direction: column;
    gap: 10px;
  }

  .popup {
    width: 80vw;
    padding: 10px;
  }

  .popup-form {
    gap: 10px;
  }

  .popup .input {
    width: 100%;
    font-size: 14px;
  }

  .submit-button {
    width: 100%;
    font-size: 14px;
  }

  .album-section-title {
    font-size: 24px;
  }

  .album-grid {
    gap: 10px;
  }

  .album-card {
    padding: 15px;
    font-size: 16px;
    max-width: 100%;
  }

  .footer {
    font-size: 12px;
  }
}